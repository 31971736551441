<template>
<div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" :title="`${pagination.itens} gatilhos`" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <!--
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar">
                                <button type="submit" class="btn btn-info">Buscar</button>
                            </div>
                            -->
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'TriggerNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo gatilho</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-1 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status p-0 p-sm-3">
                                            <p class="text-truncate title-legend-list">Ord.</p>
                                        </div>
                                   
                                        <div class="col-7 col-md-10 col-lg-10 p-0 order-3 text-center align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 col-lg-3 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Gatilho</p>
                                                    </div>
                                                    <div class="col-12 col-md-3 col-lg-2 align-self-center text-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Tipologias</p>
                                                    </div>
                                                    <div class="col-12 col-md-3 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Tipos de personalização</p>
                                                    </div>
                                                    <div class="col-12 col-md-2 col-lg-2 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Itens do tipo</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="(item, id) in triggers" :key="item.id" :id="'trigger'+id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-1 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status p-0 p-sm-3 text-center">
                                            <b>{{ item.order }}</b>
                                        </div>
                                        <div class="col-7 col-md-3 col-lg-3 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 align-self-center pad-content-list">
                                                        <h6 class="card-body-list-title">{{ item.name }}</h6>
                                                        <h6 class="fs-10 cod">{{ item.subtitle }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7 col-md-3 col-lg-2 p-0 order-3 align-self-center">
                                            <div v-for="tipology in item.tipologies" :key="tipology.id"><h6 class="card-body-list-title">{{ tipology.tipology.name }} - {{ tipology.tipology.code }}</h6></div>
                                        </div>
                                        <div v-if="item.itens.length >= 1 " class="col-6 col-md-2 text-center col-lg-2 p-0 order-3 align-self-center">
                                            <div v-for="itens in item.itens" :key="itens.id">                                        
                                                {{ itens.personalization_type.name ?? ''}}
                                            </div>                                            
                                        </div>
                                        
                                        <div v-if="item.itens.length >= 1 " class="col-7 col-md-3 col-lg-3  p-0 order-3 align-self-center">
                                            <div v-for="itens in item.itens" :key="itens.id">                                        
                                                <div v-for="personalization_type_item in itens.personalization_type_itens" :key="personalization_type_item.id">
                                                    <h6 class="card-body-list-title">{{ personalization_type_item.personalization_type_item.identification_name ?? ''}}</h6>   <h6 class="fs-10 cod">R$ {{ personalization_type_item.personalization_type_item.price ?? ''}}</h6>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div class="align-self-center d-flex align-itens-center text-right mr-1 ml-auto pr-0 order-4 pad-content-list-cta">
                                            <router-link :to="{name: 'TriggerEdit', params: { id: item.id }}" class="btn btn-info rounded-circle btn-circle font-20 card-body-list-cta" href="?app=kitacabamentos/edit-gatilho.php"><i class="icon-note font-15"></i></router-link>
                                        </div>
                                        <div class="align-self-center me-2 text-right pr-0 order-4 pad-content-list-cta">
                                            <b @click="triggerDelete(id, item.id)" class="btn btn-danger rounded-circle btn-circle font-20 card-body-list-cta"><i class="icon-trash font-15"></i></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                </div>
            </div>
                <div class="col-12">
                    <BasePagination
                        v-if="triggers.length > 0"
                        :totalPages="pagination.totalPages"
                        :activePage="pagination.currentPage"
                        @to-page="toPage"
                        @per-page="perPage" />
                </div>
            </div>
        </div>
</div>
</template>
<script>
import TriggerService from "@/services/resources/TriggerService";
const service = TriggerService.build();

export default {
    data(){
        return{
            triggers: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            search: null,
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'clientes',
                    to: 'Client'
                }
            ],
            menu:[
                {
                    name: 'Novo cliente',
                    to: 'ClientNew'
                },
                {
                    name: 'Clientes',
                    to: 'Client'
                }
            ]
        }
    },
    methods:{
         toPage(page) {
            this.pagination.currentPage = page;
            this.fetchClients();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchClients();
        },
        fetchClients(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

             service
                .search(data)
                .then(resp => {
                    this.triggers = resp.data;
                    console.log(resp.data);
                    console.log(this.triggers);
                    this.pagination.totalPages = resp.last_page;
                    this.pagination.itens = resp.total;
                })
                .catch(err => {
                console.log(err)
                })
        },
        triggerDelete(id, id2){
            this.$bvModal.msgBoxConfirm('Tem certeza que deseja excluir esse gatilho?')
            .then(value => {

            if(value) {
                let data = {
                delete: "yes",
                id: id2              
            }
                service.search(data)
                .then(resp => {
                    document.getElementById("trigger"+id).style.display="none"              
               
                    this.$bvToast.toast('Seu gatilho foi excluído com sucesso!', {
                        title: 'Excluir produto',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                    this.fetchProducts();
                })             
            }
                
            })
            .catch(err => {
            // An error occurred
            })           

        },
      
    },

    mounted(){
        this.fetchClients();
    }
}
</script>